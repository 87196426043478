button:disabled {
  opacity: 0.6;
  cursor: default;
}

.btn {
  padding: 12px;
  font-size: 14px;
  font-family: $brand-bolder-font;
  border: solid 1px #d1d1d1;
  background-color: #f9f9f9;
  border-radius: 5px;

  &-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  &.btn-small {
    padding: 6px 10px;
    font-size: 18px;
    width: 175px;
    text-align: left;

    .iconmoon-icon {
      float: right;
    }
  }

  &.btn-selected,
  &.btn-primary {
    color: $white;
    background-color: $whitelabel-primary;
    border-color: $whitelabel-primary;
  }

  @include breakpoint($mobile) {
    font-size: 20px;
    padding: 12px 20px;

    &-wrapper {
      align-items: center;
    } 
  }
}

.btn-group {
  .btn {
    margin: 0;
    border-radius: 0;
  }

  .btn:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .btn:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.multi-select-row  {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  max-width: 610px;
  padding: 0 34px;
  margin: 0 auto;

  &__option {
    height: 35px;
    padding: 0 12px;
    border-radius: 5px;
    border: solid 1px #d1d1d1;
    background-color: #f9f9f9;
    margin: 5px;
    line-height: 35px;
    font-size: 18px;
    font-family: $brand-boldest-font;
    cursor: pointer;
  
    &.selected {
        background-color: #18d665;
        border-color: #18d665;
        color: $white;
  
        &:hover {
            background-color: #f9f9f9;
            color: #18d665;
        }
    }
  
    &:hover {
        border-color: #18d665;
        color: #18d665;
    }
  }
}


        