* {
    box-sizing: border-box;
}

a {
    color: $black;
}


html, body, .App {
    // height: 100vh !important;
    background-color: #f4f4f4 !important;
    text-align: center;
}

body {
    overflow-x: hidden;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body, h1, h2, h3, p, input {
    font-family: $brand-primary-font;
}

h1 {
    font-size: $large;
}

h2 {
    font-size: $medium;
}

.IIV::-webkit-media-controls-play-button,
video::-webkit-media-controls-start-playback-button {
    opacity: 0;
    pointer-events: none;
    width: 5px;
}

label {
    line-height: 150%;
    font-size: 14px;
}

strong {
    font-family: $brand-boldest-font;
}

hr {
    border: none;
    border-bottom: 1px solid $whitelabel-line-separator;
}

input[type=number]:not(.input--show-spinner) {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.form {
    &-grid {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-gap: 1.25em 1em;
        grid-auto-flow: dense;
        align-items: center;
        max-width: 600px;
        margin: 3em auto;
    }

    &-label {
        text-align: left;
        font-size: 16px;
    }
    
    &-input {
        text-transform: uppercase;
        &::placeholder {
            color: #d5d5d5;
        }
    }

    &-button {
        grid-column: 2 / 3;
        margin: 0;

        &:hover:enabled {
            background-color: #18d665;
            color: #fff;
        }
    }
}

.date-input {
    input {
        // width: 250px;
        font-size: 16px;
        padding: 23.5px 13px;
        border: solid 1px #d1d1d1;
        box-shadow: none;
        font-family: $brand-boldest-font;
        border-radius: 5px;
        padding-left: 30px !important;
        width: 150px !important;
    }

    .bp3-icon-calendar {
        padding: 11px;
    }

    &.single {
        input {
            width: 100% !important;
            padding-left: 40px !important;
        }
    }

    &.left-align {
        text-align: left;
    }

    @include breakpoint($mobile) {
        input {
            width: 100% !important;
            font-size: 20px;
            padding-left: 40px !important;
        }
    }
}

.full-size-field {
    .bp3-popover-target {
        width: 100%;
    }
}

.full-size {
    width: 100%;
}

.inverted-cell {
    background: black;
    color: white;
    font-weight: bold;
}

.separator {
    width: 1px;
    height: 30px;
    margin: 0 15px;
    background-color: #d1d1d1;
    display: none;

    @include breakpoint($mobile) {
        display: block;
    }
}