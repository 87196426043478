.loading {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: black !important;
  opacity: 0.3;
  z-index: -1 !important;
  .loading-container {
    width: 100%;
  }
  .loader {
    z-index: 999 !important;
    margin: 0 auto;
  }
}