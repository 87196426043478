$largest: 64px;
$large: 32px;
$medium: 16px;
$small: 8px;
$smallest: 4px;

$whitelabel-primary: #18d665;

$whitelabel-blue: #00acc6;
$whitelabel-red: #ff5a5f;
$whitelabel-green: #18d665;
$whitelabel-orange: #ffa400;
$whitelabel-purple: #8B199B;
$whitelabel-lightest: #efefef;
$whitelabel-lighter: #cdcdcd;
$whitelabel-light: #8b898d;
$whitelabel-dark: #535353;
$whitelabel-line-separator: #e4e4e4;
$whitelabel-gray: #e5e5e5;

$white: white;
$black: black;
$grey: #252525;

$whitelabel-gray-bg: #fafafa;

$brand-primary-font: SofiaProLight;
$brand-bolder-font: SofiaPro;
$brand-boldest-font: SofiaProSemiBold;

/* Device config breakpoints */
$desktop: 992px;
$tablet: 768px;
$mobile: 576px;