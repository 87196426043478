.secondary-header {
  flex-direction: column;
  justify-content: space-between;

  &__wrapper {
    border-bottom: 2px solid $whitelabel-gray;
  }

  &__left {
    margin-bottom: 12px;

    .date-input {
      flex: 1;
      span {
        display: block;
      }
    }

    &-icon {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 34px;
      cursor: pointer;

      .icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__check-in {
    background-color: $whitelabel-green;
    color: white;
    padding: 10px 36px !important;
  }

  button {
    border: solid 1px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
  }

  input {
    // width: 250px;
    font-size: 14px;
    padding: 23.5px 13px;
    border: solid 1px #d1d1d1;
    background-color: #f9f9f9;
    box-shadow: none;
    font-family: $brand-boldest-font;
    border-radius: 5px;
  }

  &__dropdown {
    position: relative;

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: white;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      right: 0;
      a {
        font-family: $brand-bolder-font;
        text-decoration: none;
        font-size: 16px;
        padding: 20px !important;
        display: block;
      }
    }
    .dropdown-content:hover {
      background-color: $whitelabel-gray;
    }
  }
  &__dropdown:hover .dropdown-content {
    display: block;
  }

  @include breakpoint($mobile) {
    input {
      font-size: 20px;
    }
  }

  @include breakpoint($desktop) {
    flex-direction: row;

    &__left {
      margin-bottom: 0;
    }
  }
}
