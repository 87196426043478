.print-modal {
  position: fixed;
  z-index: 2;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);

  &__content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 60%;
  }

  &__close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    margin-top: -20px;
  }

  &__close:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  &__value {
    width: 20px;
    height: 20px;
  }

  &__main-button {
    margin-bottom: 10px;
  }

  table {
    margin: auto;
    border-collapse: collapse;
  }

  td, th {
    border: 1px solid #dddddd;
    padding: 8px;
  }

}
