$prefix: stock_check__;

.stock_check {
  &__nav-btn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ededed;
    margin: 0 15px;

    .icon {
      height: 50px;
      width: 20px;
    }

    &.drawer-close {
      position: absolute;
      top: 20px;
      right: 0;
      cursor: pointer;
    }
  }

  &__drawer {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 50px 35px;
    background-color: $white;
    min-height: 170px;
    max-height: 100%;
    overflow: auto;
    z-index: 10;
    transform: translateY(100%);
    transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
    -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);

    &.open {
      transform: translateY(0);
    }
  }

  &__drawer-buttons {
    display: flex;
    flex-flow: row wrap;
  }

  &__drawer-button {
    width: 100px;
    height: 50px;
    border-radius: 5px;
    border: solid 1px #d1d1d1;
    background-color: #f9f9f9;
    margin: 5px;
    line-height: 50px;
    font-size: 20px;
    font-family: $brand-boldest-font;
    cursor: pointer;

    &.font-small {
      font-size: 14px;
      width: 100%;
    }

    &.selected {
      background-color: $whitelabel-green;
      border-color: $whitelabel-green;
      color: $white;

      &:hover {
        background-color: #f9f9f9;
        color: $whitelabel-green;
      }
    }

    &.unchecked {
      background-color: #f26865;
      border-color: #f26865;
      color: $white;

      &:hover {
        background-color: #f9f9f9;
        color: #f26865;
      }
    }

    &.current {
      color: $black;
    }

    &:hover {
      border-color: $whitelabel-green;
      color: $whitelabel-green;
    }
  }

  &__drawer-mask {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
  }
}
