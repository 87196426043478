body {
  margin: 0;
  padding: 0;
}

@font-face {
    font-family: "SofiaPro";
    src: url("../fonts/sofia-pro.eot") format("eot"),
         url("../fonts/sofia-pro.woff") format("woff"),
         url("../fonts/sofia-pro.ttf") format("truetype")
}

@font-face {
    font-family: "SofiaProLight";
    src: url("../fonts/sofia-pro-light.eot") format("eot"),
         url("../fonts/sofia-pro-light.woff") format("woff"),
         url("../fonts/sofia-pro-light.ttf") format("truetype")
}

@font-face {
    font-family: "SofiaProSemiBold";
    src: url("../fonts/sofia-pro-semi-bold.eot") format("eot"),
         url("../fonts/sofia-pro-semi-bold.woff") format("woff"),
         url("../fonts/sofia-pro-semi-bold.ttf") format("truetype")
}

// Blueprint.js
@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";

// General components
@import 'variables';
@import 'helpers';
@import 'grid';
@import 'base';
@import 'login';
@import 'logo';
@import 'header';
@import 'loading';
@import 'buttons';
@import 'print-modal';
@import 'qr-reader';
@import "~react-toggle-switch/dist/css/switch.min.css";
@import "secondary-header";
@import "stock_check";
@import "inventory";
@import "modal";

// Page components
@import 'checkIn/check-in';
@import 'transferList/transfer-list';

@import 'spacings';
