.check-in {
  height: 90%;
  
  .rider-check-in {
    margin-bottom: 200px;
  }
  
  .switch {
    margin-top: auto;
    margin-bottom: auto;
  }
  .switch.on {
    background: $whitelabel-primary;
  }
  
  .toggle-nav {
    button:disabled {
      opacity: 1;
      color: black;
    }
    button {
      color: #949494
    }
  }
  
  a:link {
    text-decoration: none;
  }
    
  &__section-area {
    margin: 30px 0;
  }
  
  .container {
    height: 100% !important;
    width: 523px;
  }
  
  .typeahead {
    input {
      width: 400px;
      padding: 12px 20px;
      font-size: 18px;
      border: solid 1px #d1d1d1;
    }
    .typeahead-selector {
      background-color: white;
      text-align: left;
      border: solid 1px #d1d1d1;
      border-top: none;
      li {
        font-size: 18px;
        padding: 14px 20px;
        margin: 0;
        cursor: pointer;
      }
      li:hover, .hover {
        background-color: #f4f4f4;
      }
    }
  }
  
  .check-in-area {
    position: absolute;
    margin-left: 15px;
  }
  
  .previous-info {
    display: flex;
    margin-top: 25px;
    text-align: right;
    p {
      margin: 0;
      color: #949494;
    }
    span {
      display: block;
      margin-top: 3px;
      font-size: 28px;
      margin-left: 10px;
      color: red;
      font-weight: bolder;
    }
  }
  
  select, .input input {
    appearance: none;
    border-radius: 5px;
    background-color: #f9f9f9;
    border: solid 1px #d1d1d1;
    font-size: 18px;
    padding: 12px 20px;
  }
  
  .checkbox, .input {
    margin: 20px 0;
  }
  
  .input {
    border-radius: 5px;
    width: 400px !important;
    background-color: #f9f9f9;
    input {
        border: none !important;
        outline: none;
        margin: 0;
        width: 100%;
    }
    div {
      background-color: #d8d8d8;
      p {
        margin: 13px 20px;
      }
    }  
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      margin: 10px 0;
    }
  }
  select:invalid,
  select option[value=""] {
    color: #949494;
  }
  
  .button-container {
    width: 108px;
  }
  
  .btn-selected {
    font-size: 18px;
    padding: 12px 35px;
    background-color: $whitelabel-primary;
    border: solid 1px $whitelabel-primary;
    border-radius: 5px;
    color: white;
  }
  
  .btn-not-selected {
    font-size: 18px;
    padding: 12px 35px;
    background-color: #d8d8d8;
    border: solid 1px #d8d8d8;
    border-radius: 5px;
    color: white;
  }
  
  .input-field-info {
    margin: 13px 20px;
    font-size: 18px;
    color: #949494
  }
}

.checkin-button {
  background-color: #f9f9f9;
  font-size: 16px;
  padding: 10px 13px;
  border-radius: 5px;
  &-selected {
    background-color: #ffffff;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.08);
    font-size: 16px;
    padding: 10px 13px;
    border-radius: 5px;
  }
}