.header {
  z-index: 1;
  &__container {
    justify-content: space-between;
  }
  &__hub-details {
    margin: auto;
    color: white;
  }
  &__dropdown {
    position: relative;

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: white;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 999;
      right: 0;
      a {
        font-family: $brand-bolder-font;
        text-decoration: none;
        font-size: 16px;
        padding: 20px !important;
        display: block;
      }
    }
    .dropdown-content:hover {
      background-color: #e5e5e5; 
    }
  }
  &__dropdown:hover .dropdown-content {
    display: block;
  }
  &__ellipsis {
    width: 22px;
    cursor: pointer;
  }
}