.transfer-list {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 12px;
  align-items: center;
  margin: 34px auto 280px;
  padding: 0 34px;
  max-width: 670px;
  font-size: 16px;

  &__header {
      font-family: SofiaProSemiBold;
      background-color: #d34a7d;
      border-radius: 5px;
      color: #fff;
      padding: 7px 10px;
      grid-column: 1 / 3;
  }

  &__header.outbound {
    grid-column: 1 / 4;
  }

  &__meal-sku {
      font-family: SofiaProSemiBold;
  }

  &__meal-count {
      background-color: #eee;
      border-radius: 5px;
      padding: 10px;
      font-family: SofiaProSemiBold;
  }

  &__header__factory {
    font-family: SofiaProSemiBold;
    background-color: #d34a7d;
    border-radius: 5px;
    color: #fff;
    padding: 7px 10px;
    grid-column: 1 / 7;
  }

  @include breakpoint($mobile) {
      font-size: 20px;
  }
}

.transfer-list.factory {
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr 1fr;
  max-width: 1024px;
}

.transfer-list.outbound {
  grid-template-columns: 2fr 1fr 1fr;
}